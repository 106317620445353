<template>
  <div class="flex gap-6 flex-col">
    <Accordion header="Información básica del informe">
      <div class="px-5 pb-8 flex flex-wrap gap-2 w-full flex-col">
        <div class="w-2/3">
          <h2 class="text-left text-md text-blue-dark font-bold capitalize">
            <span class="text-gray-800">Entidad: </span> {{ sfc_name }}
          </h2>
        </div>
        <h4 class="text-left text-md font-bold">Descripción</h4>
        <p class="text-justify text-md bg-gray-50 rounded-xl p-4">{{ description }}</p>
      </div></Accordion
    >
    <Step1 lectureMode />
    <Step2 lectureMode />
    <Step3 lectureMode />
    <Step4 lectureMode />
    <Step5 lectureMode />
  </div>
</template>
<script>
import Step1 from "@/components/Dashboard/FCO/Reports/FormSteps/Step1.vue";
import Step2 from "@/components/Dashboard/FCO/Reports/FormSteps/Step2.vue";
import Step3 from "@/components/Dashboard/FCO/Reports/FormSteps/Step3.vue";
import Step4 from "@/components/Dashboard/FCO/Reports/FormSteps/Step4.vue";
import Step5 from "@/components/Dashboard/FCO/Reports/FormSteps/Step5.vue";
import ReportForm from "@/components/Dashboard/FCO/Reports/ReportForm.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { ReportForm, Step1, Step2, Step3, Step4, Step5, Accordion },
  data() {
    return {
      sfc_name: "",
      description: "",
    };
  },
  created() {
    this.getStep0();
  },
  methods: {
    async getStep0() {
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        0
      );
      if (data) {
        this.sfc_name = data.data.general.company.name_company;
        this.description = data.data.general.description;
      }
      if (error) {
        this.showToast(
          "error",
          "Ocurrió un error obteniendo la información básica del informe."
        );
      }
    },
  },
  computed: {
    optionsCompanies: function () {
      return this.$store.state.form?.optionsCompanies?.filter(
        (item) => item?.direction == this.$store.state?.session?.user.direction
      );
    },
  },
};
</script>
<style></style>
