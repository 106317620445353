<template><HistoryReportDetails /></template>
<script>
import HistoryReportDetails from "@/components/Dashboard/FCO/Reports/HistoryReportDetails.vue";

export default {
  components: { HistoryReportDetails },
  name: "reportView",
};
</script>
<style></style>
